import type { GetStaticProps, InferGetStaticPropsType } from 'next';
import { getErrorPage } from 'utilities/getErrorPage';

import SduPage, { type PageProps } from './[[...slug]]';

/**
 * The not found page component.
 *
 * @param {PageProps} props - The props for the component.
 * @param {Object} props.seo - The SEO data for the page.
 * @param {string} props.slug - The slug for the page.
 * @param {string} props.category - The category for the page.
 * @param {Object[]} props.sectionsCollection - The sections for the page.
 * @param {Object[]} props.navigation - The navigation links for the page.
 * @param {Object} props.footer - The footer data for the page.
 * @returns {JSX.Element} - The not found page component.
 */
const NotFoundPage = ({
  seo,
  slug,
  category,
  sectionsCollection,
  navigation,
  footer,
}: InferGetStaticPropsType<typeof getStaticProps>): JSX.Element => (
  <SduPage
    seo={seo}
    slug={slug}
    category={category}
    sectionsCollection={sectionsCollection}
    navigation={navigation}
    footer={footer}
  />
);

/**
 * Gets the static props for the not found page.
 *
 * @param {Object} context - The context for the function.
 * @param {boolean} context.preview - Whether the page is in preview mode or not.
 * @returns {Promise<Object>} - The static props for the not found page.
 */
export const getStaticProps: GetStaticProps<PageProps> = async ({ preview = false }) => {
  return await getErrorPage({ preview, statusCode: 404 });
};

export default NotFoundPage;
